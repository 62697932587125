@import url('https://fontlibrary.org//face/single-day-regular');
@import url('https://fonts.googleapis.com/css?family=Sofia');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: "#d7fdfa";
  width: 100%;
  height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
